import Countdown from 'constant/countdown'
import Link from 'next/link'
import { getUserDetails } from '@/pages/api/api'
import { getAuthKey, getSelectedTenantDetails } from 'constant/functions'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  MixpanelEventName,
  MixpanelPropertiesName,
  MixpanelPropertiesValues,
  MixpanelTracking,
} from 'constant/mixpnael'
import axios from 'axios'
import ReactCountryFlag from 'react-country-flag'

const TopNotification = ({ setHideNt, hideNt }) => {
  const router = useRouter()

  const [userDetails, setUserDetails] = useState(null)
  const [tenantDetails, setTenantDetails] = useState(null)

  // user Details and tenant details fetching
  useEffect(() => {
    if (getAuthKey() !== undefined) {
      const auth_key = getAuthKey()
      if (auth_key) {
        getUserDetails(auth_key).then((res) => {
          if (res?.user) {
            setUserDetails(res.user)
          }
        })

        axios
          .get('/api/user_tenants_details', { params: { auth_key: getAuthKey() } })
          .then((res) => {
            setTenantDetails(getSelectedTenantDetails(res.data.data.tenants))
          })
          .catch((err) => {
            if (err?.response?.data?.error?.message == 'unauthorized') {
              window.location.reload()
            } else {
              err?.response?.data?.error?.message && alert(err?.response?.data?.error?.message)
            }
          })
      }
    }
  }, [router])

  //onCloseTopHeader
  const onCloseTopHeader = () => {
    setHideNt(!hideNt)
    localStorage.setItem('hideNt', !hideNt)
  }

  return (
    <div class="  py-4 rounded-sm text-sm  bg-white shadow relative ">
      <div class="max-w-6xl mx-auto px-4 w-full flex  flex-col md:flex-row justify-center  items-center gap-2">
        <div class=" flex-grow flex  flex-col lg:flex-row items-center  justify-center  gap-5 lg:gap-10 font-medium h-full w-full lg:w-auto my-auto">
          <div className="flex flex-col justify-center items-center gap-0.5  ">
            <p className="text-lg  animate-charcter text-center">
              Special Offer: 25% off on all plans. Use code: "FREEDOM25".
            </p>
            <p className=" text-xs text-black font-light  ">* Only for new paid subscription</p>
          </div>
        </div>

        <div className=" md:flex-none flex flex-row items-center gap-3">
          {/* <Link href={'/workspace/plans'}>
            <a className="font-medium inline-flex items-center justify-center border border-primary bg-primary hover:bg-transparent text-white hover:text-primary rounded leading-snug transition duration-150 ease-in-out px-8 py-2">
              See Plan
            </a>
          </Link> */}
          <button
            class=" absolute md:relative top-0 bottom-0 my-auto right-2 md:right-0 top opacity-70 hover:opacity-80   "
            onClick={() => onCloseTopHeader()}
          >
            <div class="sr-only">Close</div>
            <svg class="w-4 h-4 fill-current">
              <path d="M7.95 6.536l4.242-4.243a1 1 0 111.415 1.414L9.364 7.95l4.243 4.242a1 1 0 11-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 01-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 011.414-1.414L7.95 6.536z"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  )
}

export default TopNotification
