import { tagTypes } from '@/redux/api/TagTypes'
import { apiSlice } from '@/redux/api/apiSlice'

export const languageApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Get  user details
    getTenantLanguages: builder.query({
      query: () => {
        return `/language/tenant`
      },
      providesTags: [tagTypes.tenant_languages],
    }),
    getAllLanguages: builder.query({
      query: () => {
        return `/language`
      },
      providesTags: [tagTypes.all_languages],
    }),
  }),
})

export const { useGetTenantLanguagesQuery, useGetAllLanguagesQuery } = languageApi
