import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')
const {
  auth_cookie_name,
  refresh_cookie_name,
  uuid_cookie_name,
  tenant_cookie_name,
} = require('./url')

// Get Auth key
export function getAuthKey() {
  let ckAuth = Cookies.get(auth_cookie_name)
  if (ckAuth !== undefined) {
    const decrypted = CryptoJS.AES.decrypt(ckAuth, process.env.AES_SECRET)
    var aut_key = decrypted.toString(CryptoJS.enc.Utf8)
    return aut_key
  } else {
    return undefined
  }
}

// Get Refresh key
export function getRefreshKey() {
  let ckAuth = Cookies.get(refresh_cookie_name)
  if (ckAuth !== undefined) {
    const decrypted = CryptoJS.AES.decrypt(ckAuth, process.env.AES_SECRET)
    var aut_key = decrypted.toString(CryptoJS.enc.Utf8)
    return aut_key
  } else {
    return undefined
  }
}

// GEt UUID
export function getUUID() {
  let ck_uuid = Cookies.get(uuid_cookie_name)
  if (ck_uuid !== undefined) {
    const decrypted = CryptoJS.AES.decrypt(ck_uuid, process.env.AES_SECRET)
    var uuid = decrypted.toString(CryptoJS.enc.Utf8)
    return uuid
  } else {
    return undefined
  }
}

// GET SSR Auth Key
export function getSSRAuthKey(auth_key) {
  let ckAuth = auth_key
  if (ckAuth !== undefined) {
    const decrypted = CryptoJS.AES.decrypt(ckAuth, process.env.AES_SECRET)
    var aut_key = decrypted.toString(CryptoJS.enc.Utf8)
    return aut_key
  } else {
    return undefined
  }
}

// Get SSR refresh key
export function getSSRRefreshKey(refresh_key) {
  let ckAuth = refresh_key
  if (ckAuth !== undefined) {
    const decrypted = CryptoJS.AES.decrypt(ckAuth, process.env.AES_SECRET)
    var aut_key = decrypted.toString(CryptoJS.enc.Utf8)
    return aut_key
  } else {
    return undefined
  }
}

// Encrypt data with AES
export function encryptDataWithAES(data) {
  return CryptoJS.AES.encrypt(data, process.env.AES_SECRET).toString()
}
export function templateEncryptDataWithAES(data) {
  return CryptoJS.AES.encrypt(data, process.env.TEMPLATE_SECRET_KEY).toString()
}

//Decreypt
export function decryptDataWithAES(data) {
  var bytes = CryptoJS.AES.decrypt(data, process.env.AES_SECRET)
  return bytes.toString(CryptoJS.enc.Utf8)
}
export function templateDecryptDataWithAES(data) {
  var bytes = CryptoJS.AES.decrypt(data, process.env.TEMPLATE_SECRET_KEY)
  return bytes.toString(CryptoJS.enc.Utf8)
}

// Get Thumbnail Image
export function getThumbnailImage(file) {
  let filename = file.split('/').pop()
  let fileURl = file.replace(filename, 'thumb_' + filename)

  return fileURl
}

// Handle Enter press
export const handleEnterKeyPress = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault()
  }
}

//Decrypt Auth key
export function decryptAuthKey(ckAuth) {
  if (ckAuth !== undefined) {
    const decrypted = CryptoJS.AES.decrypt(ckAuth, process.env.AES_SECRET)
    var auth_key = decrypted.toString(CryptoJS.enc.Utf8)
    return auth_key
  } else {
    return undefined
  }
}

// Get Selected Tenant
export const getSelectedTenantDetails = (tenants) => {
  let ckTenant = Cookies.get(tenant_cookie_name)

  if (ckTenant) {
    const decrypted = CryptoJS.AES.decrypt(ckTenant, process.env.AES_SECRET)
    var selected_tenant_id = decrypted.toString(CryptoJS.enc.Utf8)

    return tenants?.filter((tenant) => tenant.id == selected_tenant_id)[0]
  } else {
    return tenants?.filter((tenant) => tenant.default)[0]
  }
}

// Get Selected Tenant from SSR
export const getSSRSelectedTenantDetails = ({ tenants, req }) => {
  let ckTenant = req.cookies[tenant_cookie_name]

  if (ckTenant) {
    const decrypted = CryptoJS.AES.decrypt(ckTenant, process.env.AES_SECRET)
    var selected_tenant_id = decrypted.toString(CryptoJS.enc.Utf8)

    return tenants?.filter((tenant) => tenant.id == selected_tenant_id)[0]
  } else {
    return tenants?.filter((tenant) => tenant.default)[0]
  }
}
