import Transition from '@/utils/Transition'
import { cookies_domain_name } from 'constant/url'
import { setCookies } from 'cookies-next'
import React, { useRef, useEffect } from 'react'

function ModalBlank({ children, id, modalOpen, setModalOpen }) {
  const modalContent = useRef(null)

  return (
    <>
      {/* Modal backdrop */}
      <Transition
        className="fixed  bg-gray-900 bg-opacity-30 inset-0   z-100  "
        show={modalOpen}
        enter="transition ease-out duration-200"
        enterStart="opacity-0"
        enterEnd="opacity-100"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
        aria-hidden="true"
      />
      {/* Modal dialog */}
      <Transition
        id={id}
        className="fixed inset-0  z-100 overflow-hidden flex items-center my-4 justify-center transform px-4 sm:px-6"
        role="dialog"
        aria-modal="true"
        show={modalOpen}
        enter="transition ease-in-out duration-200"
        enterStart="opacity-0 translate-y-4"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-in-out duration-200"
        leaveStart="opacity-100 translate-y-0"
        leaveEnd="opacity-0 translate-y-4"
      >
        <div
          ref={modalContent}
          className="bg-white rounded  overflow-auto max-w-4xl w-full max-h-full"
        >
          {children}
        </div>
      </Transition>
    </>
  )
}

export default ModalBlank
