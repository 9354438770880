// NOTE: This common Address component for Add account , edit account , add store and edit store .

/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useEffect } from 'react'
import OutsideClickHandler from 'react-outside-click-handler'

const SelectDropDown = ({
  drop_down_values, //This is drop dow values in array example:[{label:...,value:...}]
  setSelectedItem, //it will function
  selectedItem, //selected item full object example:{label:...,value:...}
  placeholder_text,
  not_found_text,
  id,
}) => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false)
  const [isFilter, setIsFilter] = useState(false)
  const [filter_drop_down_data, setFilter_dropdown_data] = useState(null)
  const [input_value, setInputValue] = useState(selectedItem ? selectedItem?.label : '')

  useEffect(() => {
    setFilter_dropdown_data(
      drop_down_values?.filter((item) =>
        item?.label?.toLowerCase()?.includes(input_value?.toLowerCase())
      )
    )
  }, [input_value])
  useEffect(() => {
    if (selectedItem) {
      setInputValue(selectedItem.label)
    }
  }, [selectedItem])

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        isDropDownOpen && (setIsDropDownOpen(false), setIsFilter(''))
      }}
    >
      <div className=" w-full relative">
        <input
          id={id}
          value={input_value}
          type="text"
          className="
                    mt-0
                    block
                    w-full
                    pl-6 py-[9px]  pr-10   outline-none rounded-md shadow
                    border border-gray-200 transition  duration-700
                    focus:ring-2 focus:ring-primary  text-ellipsis
                  "
          placeholder={placeholder_text}
          onClick={() => {
            setIsDropDownOpen(!isDropDownOpen)
          }}
          onChange={(e) => {
            setIsDropDownOpen(true)
            setIsFilter(true)
            setInputValue(e.target.value)
            setSelectedItem(null)
          }}
        />
        <div className=" absolute bottom-0 top-0  right-4   flex items-center justify-center text-gray-700">
          {isDropDownOpen ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-5 h-5"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          )}
        </div>
        {isDropDownOpen && (
          <div className=" absolute w-full  max-h-[300px] rounded-md bg-white overflow-y-scroll border  border-gray-200  mt-[9px]  z-10 shadow">
            {isFilter ? (
              filter_drop_down_data?.length > 0 ? (
                filter_drop_down_data?.map((filter_item) => {
                  return (
                    <div
                      key={Math.random()}
                      className={[
                        ' flex  py-[10px] px-3 md:px-8 cursor-pointer border border-transparent   hover:border-primary     ',
                        selectedItem?.value === filter_item?.value
                          ? 'bg-primary text-white'
                          : ' text-black  bg-transparent',
                      ].join(' ')}
                      onClick={() => {
                        setSelectedItem(filter_item)
                        setInputValue(filter_item.label)
                        setIsDropDownOpen(false)
                        setIsFilter(false)
                        setFilter_dropdown_data(null)
                      }}
                    >
                      <span className=" text-sm  font-normal ltr:ml-2 rtl:mr-2">
                        {filter_item.label}
                      </span>
                    </div>
                  )
                })
              ) : (
                <div
                  className=" bg-yellow-400   text-white px-4 py-3 rounded-card  relative grid grid-cols-[10%,80%,10%]"
                  role="alert"
                >
                  <div className="flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                      />
                    </svg>
                  </div>
                  <div>
                    <span className="  ltr:ml-2 rtl:mr-2">{not_found_text}</span>
                  </div>
                </div>
              )
            ) : drop_down_values?.length > 0 ? (
              drop_down_values?.map((filter_item) => {
                return (
                  <div
                    key={Math.random()}
                    className={[
                      ' flex  py-[10px] px-3  md:px-8 cursor-pointer border border-transparent   hover:border-primary     ',
                      selectedItem?.value === filter_item?.value
                        ? 'bg-primary text-white'
                        : ' text-black  bg-transparent',
                    ].join(' ')}
                    onClick={() => {
                      setSelectedItem(filter_item)
                      setIsDropDownOpen(false)
                      setInputValue(filter_item.label)
                      setIsFilter(false)
                      setFilter_dropdown_data(null)
                    }}
                  >
                    <span className=" text-sm  font-normal  ml-2  ">{filter_item.label}</span>
                  </div>
                )
              })
            ) : (
              <div
                className=" bg-yellow-400   text-white px-4 py-3 rounded-sm  relative grid grid-cols-[10%,80%,10%]"
                role="alert"
              >
                <div className="flex items-center justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </div>
                <div>
                  <span className="   ml-2  ">{not_found_text}</span>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  )
}

export default SelectDropDown
