import { useGetTenantDetailsQuery, useGetUserDetailsQuery } from '@/redux/features/users/userApi'
import {
  MixpanelEventName,
  MixpanelPropertiesName,
  MixpanelPropertiesValues,
  MixpanelTracking,
} from 'constant/mixpnael'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'

const AskButton = () => {
  const router = useRouter()
  const { data: userDetails } = useGetUserDetailsQuery({})
  const { data: tenantDetails } = useGetTenantDetailsQuery({})

  return (
    !router.pathname.startsWith('/launchpad') &&
    !router.pathname.startsWith('/support') &&
    !router.pathname.startsWith('/add_page') &&
    !router.pathname.startsWith('/edit_page') &&
    !router.pathname.startsWith('/workspace') &&
    !router.pathname.startsWith('/editor') &&
    !router.pathname.startsWith('/signup') &&
    !router.pathname.startsWith('/onboarding') &&
    !router.pathname.startsWith('/embed') &&
    !router.pathname.startsWith('/info') && (
      <>
        <div className="hidden lg:block">
          <Link href={`https://community.tradly.app/?utm_source=tradly.app&utm_medium=sidebar`}>
            <a
              className="    z-50  fixed inset-y-0  -right-8  my-auto  btn_outline border border-gray-800 text-gray-200 hover:text-gray-800   bg-gray-800  hover:bg-transparent w-full   max-w-[100px] h-8 transform     rotate-90"
              target="_blank"
              onClick={() => {
                MixpanelTracking(MixpanelEventName.homepage_sticky_buttons, {
                  [MixpanelPropertiesName.user_name]: userDetails?.name,
                  [MixpanelPropertiesName.user_email]: userDetails?.email,
                  [MixpanelPropertiesName.tenant_name]: tenantDetails?.id,
                  [MixpanelPropertiesName.homepage_sticky_buttons_property]:
                    MixpanelPropertiesValues.homepage_sticky_button_community,
                })
              }}
            >
              <span>Ask</span>
            </a>
          </Link>
        </div>
      </>
    )
  )
}

export default AskButton
