import { apiSlice } from '@/redux/api/apiSlice'
import { configureStore } from '@reduxjs/toolkit'
import { sidebarSlice } from './features/sidebar/SidebarSlice'

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    sidebar: sidebarSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: false,
})

export default store
