import SelectDropDown from '@/Shared/SelectDropDown'
import { useAppContext } from '@/context_store/AppContext'
import { useGetCurrenciesQuery } from '@/redux/features/currency/currencyApi'
import {
  useGetAllLanguagesQuery,
  useGetTenantLanguagesQuery,
} from '@/redux/features/language/languageApi'
import axios from 'axios'
import { configs_keys } from 'constant/ConfigsKey'
import { check_login } from 'constant/check_auth'
import { getAuthKey } from 'constant/functions'
import { MixpanelEventName, MixpanelPropertiesName, MixpanelTracking } from 'constant/mixpnael'
import { product_interested } from 'data/additional_information'
import { disable } from 'mixpanel-browser'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { currency_list } from 'data/CurrencyData'
import { currency_and_language_setup } from '../actions/CurrencyAndLanguage'
import { loading_icon } from 'constant/AllIcons'
import { useDispatch } from 'react-redux'
import { useHashROuter } from '@/hooks/useHashRouter'
import { calculatePercentage } from '@/utils/getPercent'

const LanguageAndCurrencySetup = ({ onSuccessSetup }) => {
  const router = useHashROuter()
  const { hash_name } = router
  const dispatch = useDispatch()
  const { data: all_languages } = useGetAllLanguagesQuery()
  const { data: tenant_languages } = useGetTenantLanguagesQuery()
  const { data: tenant_currencies } = useGetCurrenciesQuery()

  // app context
  const { state } = useAppContext()

  //
  const [isLoading, setIsLoading] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState(null)
  const [selectedCurrency, setSelectedCurrency] = useState(null)

  const [editable_ids, setEditable_ids] = useState(null)

  //
  useEffect(() => {
    const tenant_default_language = tenant_languages?.languages?.find((tl) => tl.default)
    let tenant_default_currency = tenant_currencies?.currencies?.find((tc) => tc?.default)

    let default_language = tenant_default_language
      ? {
          ...tenant_default_language,
          id: all_languages?.languages?.find((lang) => lang.code == tenant_default_language?.code)
            ?.id,
        }
      : all_languages?.languages?.filter((lang) => lang.code == 'en')[0]
    let default_currency = tenant_default_currency
      ? tenant_default_currency
      : currency_list?.filter((cur) => cur.code === 'USD')[0]

    if (
      tenant_default_language &&
      tenant_default_currency &&
      tenant_default_language?.id &&
      tenant_default_currency?.id
    ) {
      setEditable_ids({
        language_id: tenant_default_language?.id,
        currency_id: tenant_default_currency?.id,
      })
    }
    setSelectedLanguage({ label: default_language?.name, value: default_language?.id })
    setSelectedCurrency({ label: default_currency?.name, value: default_currency?.code })
  }, [all_languages, tenant_languages, tenant_currencies])

  //
  const onFormSubmit = (e) => {
    e.preventDefault()
    let currency_details = currency_list.filter(
      (currency) => currency.code === selectedCurrency.value
    )[0]

    let selected_currency = {
      name: currency_details?.name,
      default: true,
      active: true,
      order_by: 0,
      code: currency_details?.code,
      exchange_rate: '1',
      precision: currency_details.precision,
      thousand_separator: ',',
      decimal_point: '.',
      format: currency_details.format,
    }

    //
    currency_and_language_setup({
      selected_currency,
      router,
      selected_language: selectedLanguage,
      setIsLoading,
      onSuccessSetup,
      editable_ids,
      dispatch,
    })
  }

  return (
    <div className="pt-10 pb-16 px-4 min-h-screen bg-[#F0F7F5]">
      <form
        onSubmit={onFormSubmit}
        className=" bg-white shadow-onboarding_card p-8 rounded-3xl max-w-fit mx-auto min-h-[800px]  flex flex-col "
      >
        <div className=" max-w-3xl  w-full  mx-auto   flex flex-col items-center px-8   flex-grow">
          <div className="flex flex-col items-center justify-center  gap-2  pb-12 md:pb-20">
            <h1 className="h1 text-center">Language and Currency</h1>
            <p className="text-base font-normal text-gray-800">Setup language and currency</p>
          </div>
          {/* Language */}
          <div className=" w-full flex items-center gap-7 md:gap-14 ">
            <div className=" flex-grow flex flex-col items-start justify-start gap-3 w-full ">
              <h4 className="h4">Language</h4>
              <p className="text-base font-normal text-gray-800">
                Select default language. If you select Arabic, RTL will be activated in your end
                user website. You can configure multiple languages via SuperAdmin{' '}
              </p>
            </div>
            <div className="  w-full  ">
              <SelectDropDown
                drop_down_values={all_languages?.languages.map((item) => {
                  return { label: item.name, value: item.id }
                })}
                setSelectedItem={(item) => {
                  setSelectedLanguage(item)
                }}
                selectedItem={selectedLanguage}
                placeholder_text={'Select'}
                not_found_text={'No option'}
                id={'language'}
              />
            </div>
          </div>
          {/* Currencies */}
          <div className=" w-full flex items-center  gap-7 md:gap-14 mt-10   ">
            <div className="flex flex-col items-start justify-start gap-3 w-full ">
              <h4 className="h4">Currency</h4>
              <p className="text-base font-normal text-gray-800">
                Select default currency. Add multiple currencies later
              </p>
            </div>
            <div className="  w-full  ">
              <SelectDropDown
                drop_down_values={currency_list.map((item) => {
                  return { label: item.name, value: item.code }
                })}
                setSelectedItem={(item) => {
                  setSelectedCurrency(item)
                }}
                selectedItem={selectedCurrency}
                placeholder_text={'Select'}
                not_found_text={'No option'}
                id={'language'}
              />
            </div>
          </div>
        </div>

        <div className=" w-full mx-auto mt-12 md:mt-20 px-28 md:px-8  ">
          <div className=" flex items-center justify-between">
            <button
              type="button"
              onClick={() => router.push('/onboarding')}
              className={` btn_primary_outline   gap-3 mr-auto`}
            >
              <span>Back</span>
            </button>
            <button
              type="submit"
              disabled={isLoading}
              className={` btn_primary flex items-center gap-3 ml-auto`}
            >
              <span>Continue</span>
              {isLoading && loading_icon}
            </button>
          </div>

          <div className=" w-full  mt-4 ">
            <div className=" w-full h-1.5 rounded-lg bg-[#E6E7E7]  relative">
              <div
                className=" bg-primary absolute h-1  "
                style={{
                  width: `${calculatePercentage(6, Number(hash_name) + 1)}%`,
                }}
              />
            </div>

            <p className=" text-sm mt-2 text-[#1A2928]"> Step {Number(hash_name) + 1} of 6</p>
          </div>
        </div>
      </form>
    </div>
  )
}

export default LanguageAndCurrencySetup
