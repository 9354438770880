import React, { useEffect, useState } from 'react'

import { useGetCurrenciesQuery } from '@/redux/features/currency/currencyApi'
import { useGetTenantLanguagesQuery } from '@/redux/features/language/languageApi'
import LanguageAndCurrencySetup from './LanguageAndCurrencySetup'
import ModalBlank from '@/Shared/modal/ModalBlank'

function LanguageCurrencyInModal() {
  const {
    data: currencies,
    isLoading: isCurrencyLoading,
    isFetching: isCurrencyFetching,
    isError: isCurrencyError,
  } = useGetCurrenciesQuery()
  const {
    data: tenant_languages,
    isLoading: isLanguageLoading,
    isFetching: isLanguageFetching,
    isError: isLanguageError,
  } = useGetTenantLanguagesQuery()

  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (
      tenant_languages?.length === 0 &&
      currencies?.length === 0 &&
      isCurrencyLoading &&
      !isCurrencyFetching &&
      !isCurrencyError &&
      !tenant_languages &&
      !isLanguageLoading &&
      !isLanguageFetching &&
      !isLanguageError
    ) {
      setModalOpen(true)
    }

    return () => {
      setModalOpen(false)
    }
  }, [currencies, tenant_languages])

  return (
    <ModalBlank id="setup-modal" modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <LanguageAndCurrencySetup
        onSuccessSetup={(e) => {
          setModalOpen(false)
        }}
      />
    </ModalBlank>
  )
}

export default LanguageCurrencyInModal
