/* eslint-disable no-use-before-define */
import axios from 'axios'
import { getAuthKey } from 'constant/functions'
import { base_url } from 'constant/url'

export const api_call = async ({ method, path, data, params, onSuccess, onError }) => {
  var header = {
    'Content-Type': 'application/json',
    ...params,
  }
  if (getAuthKey() && path !== '/skua/users/token/refresh') {
    header['x-auth-key'] = getAuthKey()
  }

  try {
    let parameters = {
      url: base_url + path,
      method: method,
      responseType: 'json',
      headers: header,
    }
    if (method !== 'GET' && Object.keys(data).length > 0) {
      parameters.data = data ?? {}
    }
    const response_json = await axios(parameters)
    // console.log(response_json)
    return onSuccess(response_json.data?.data)
  } catch (error) {
    return onError(error?.response?.data?.error)
  }
}

export const api_call_with_auth_key = async ({
  method,
  path,
  data,
  auth_key,
  onSuccess,
  onError,
}) => {
  var header = {
    'Content-Type': 'application/json',
  }
  if (auth_key) {
    header['x-auth-key'] = auth_key
  }

  try {
    let parameters = {
      url: base_url + path,
      method: method,
      responseType: 'json',
      headers: header,
    }
    if (method !== 'GET') {
      parameters.data = data ?? {}
    }
    const response_json = await axios(parameters)
    // console.log(response_json)
    return onSuccess(response_json.data?.data)
  } catch (error) {
    return onError(error?.response?.data?.error)
  }
}
