import React, { useState, useRef, useEffect } from 'react'
import Link from 'next/link'
import Transition from '@/utils/Transition'
import axios from 'axios'
import { logout_tradly } from '@/components/Auth/Logout'
import { menus_icon } from '@/components/workspaces/sidebar_menus'
import { useGetTenantDetailsQuery, useGetUserDetailsQuery } from '@/redux/features/users/userApi'

function UserMenu() {
  //
  const { data: userDetails, isLoading, isError, error } = useGetUserDetailsQuery({})
  const { data: tenantDetails } = useGetTenantDetailsQuery({})

  //
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const trigger = useRef(null)
  const dropdown = useRef(null)

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target))
        return
      setDropdownOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return
      setDropdownOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  // user Details and tenant details fetching
  useEffect(() => {
    if (userDetails) {
      // initial set (For solve error)
      localStorage.setItem(
        'sub_users.manage',
        JSON.stringify({
          role_read: true,
          role_write: true,
          role_delete: true,
        })
      )

      axios
        .get('/api/get_modules_by_user', {
          params: { user_id: userDetails.id },
        })
        .then((res) => {
          const sub_user_data = res.data.data.modules.filter(
            (md) => md.module === 'sub_users.manage'
          )
          if (sub_user_data.length > 0) {
            localStorage.setItem(
              'sub_users.manage',
              JSON.stringify({
                role_read: sub_user_data[0].role_read,
                role_write: sub_user_data[0].role_write,
                role_delete: sub_user_data[0].role_delete,
              })
            )
          } else {
            localStorage.setItem(
              'sub_users.manage',
              JSON.stringify({
                role_read: true,
                role_write: true,
                role_delete: true,
              })
            )
          }
        })
        .catch((error) => {
          if (error?.response?.data?.error?.code === 953) {
            localStorage.setItem(
              'sub_users.manage',
              JSON.stringify({
                role_read: true,
                role_write: true,
                role_delete: true,
              })
            )
          } else {
            if (error.response.data.error.message == 'unauthorized') {
              window.location.reload()
            } else {
              error?.response?.data?.error?.message && alert(error.response.data.error.message)
            }
          }
        })
    }
  }, [userDetails])

  return userDetails ? (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        {userDetails.profile_pic ? (
          <img
            className="w-8 h-8 rounded-full"
            src={userDetails.profile_pic}
            width="32"
            height="32"
            alt="User"
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-8 h-8 rounded-full text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        )}
        <div className=" hidden md:flex items-center truncate">
          <span className="truncate ml-2 text-sm font-medium group-hover:text-gray-800">
            {userDetails.name}
          </span>
          <svg
            className="w-3 h-3 flex-shrink-0 ml-1 fill-current text-gray-400"
            viewBox="0 0 12 12"
          >
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

      <Transition
        className="origin-top-right z-10 absolute top-full right-0  min-w-[150px] bg-white border border-gray-200 py-1.5 rounded shadow-lg overflow-hidden mt-1"
        show={dropdownOpen}
        enter="transition ease-out duration-200 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-200"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          <ul>
            <li>
              <Link href="https://superadmin.tradly.app">
                <a
                  target="_blank"
                  className="   font-medium text-sm text-gray-700 hover:text-primary flex items-center py-1 px-3 gap-2"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {menus_icon.super_admin}
                  SuperAdmin
                </a>
              </Link>
            </li>
            <li>
              <Link href="/workspace">
                <a
                  className="   font-medium text-sm text-gray-700 hover:text-primary flex items-center py-1 px-3 gap-2"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {menus_icon.cube}
                  Workspace
                </a>
              </Link>
            </li>
            <li>
              <Link href="/editor">
                <a
                  className="   font-medium text-sm text-gray-700 hover:text-primary flex items-center py-1 px-3 gap-2"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {menus_icon.editor}
                  Web Editor
                </a>
              </Link>
            </li>
            <li>
              <Link href="https://marketplace.tradly.app">
                <a
                  className="   font-medium text-sm text-gray-700 hover:text-primary flex items-center py-1 px-3 gap-2"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  target="_blank"
                >
                  {menus_icon.marketplace}
                  Marketplace
                </a>
              </Link>
            </li>

            <li
              onClick={() => {
                logout_tradly()
              }}
            >
              <a className="   font-medium text-sm text-gray-700 hover:text-primary flex items-center py-1 px-3 cursor-pointer gap-2">
                {menus_icon.logout_icon}
                Logout
              </a>
            </li>
            <li>
              <a className=" font-normal text-sm text-gray-400   italic  flex items-center py-1 px-3  cursor-text">
                {tenantDetails?.id}
              </a>
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  ) : (
    <div></div>
  )
}

export default UserMenu
