module.exports = {
  auth_url: process.env.ENVIRONMENT.includes('dev')
    ? 'https://auth.dev.tradly.app/'
    : 'https://auth.tradly.app/',
  base_url: process.env.ENVIRONMENT.includes('dev')
    ? 'https://api.dev.tradly.app'
    : 'https://api.tradly.app',
  base_marketplace_url: 'https://api.tradly.app',
  base_proxy_url: process.env.ENVIRONMENT.includes('dev')
    ? 'https://dev.tradly.app/api'
    : 'https://tradly.app/api',
  auth_cookie_name: process.env.ENVIRONMENT.includes('dev') ? 'ck_d_a' : 'ck_p_a',
  site_url: process.env.ENVIRONMENT.includes('dev')
    ? 'https://dev.tradly.app'
    : 'https://tradly.app',
  refresh_cookie_name: process.env.ENVIRONMENT.includes('dev') ? 'ck_d_r' : 'ck_p_r',
  uuid_cookie_name: process.env.ENVIRONMENT.includes('dev') ? 'ck_d_u' : 'ck_p_u',
  global_auth_cookie_name: process.env.ENVIRONMENT.includes('dev') ? 'ck_d_ga' : 'ck_p_ga',
  tenant_cookie_name: process.env.ENVIRONMENT.includes('dev') ? 'ck_d_t' : 'ck_p_t',
  default_site_url: process.env.ENVIRONMENT.includes('dev')
    ? 'https://superadmin.dev.tradly.app'
    : `https://superadmin.tradly.app`,

  cookies_domain_name: process.env.ENVIRONMENT.includes('dev') ? '.tradly.app' : '.tradly.app',
  web_hook: '/v1/webhooks',
}
