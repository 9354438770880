import { getAuthKey } from './functions'
import { site_url } from './url'

export const check_login = (router, isRedirect) => {
  if (getAuthKey()) {
    return true
  } else {
    isRedirect &&
      typeof window !== 'undefined' &&
      router.push(`/signin?to=${site_url}${router.asPath}`)
    return false
  }
}
