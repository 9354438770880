import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { tagTypesList } from './TagTypes'
import { getAuthKey, getRefreshKey } from 'constant/functions'
import { base_proxy_url, base_url } from 'constant/url'
import { logout_tradly } from '@/components/Auth/Logout'

//
const baseQuery = fetchBaseQuery({
  baseUrl: base_proxy_url,
  prepareHeaders(headers, { getState }) {
    if (getAuthKey()) {
      headers.set('x-auth-key', `${getAuthKey()}`)
    }
    return headers
  },
})

export const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions)

  if (result.error && result.error.data.code === 401 && getRefreshKey()) {
    // try to get a new token
    const refreshResult = await baseQuery('/refresh_token', api, extraOptions)

    if (refreshResult.data) {
      result = await baseQuery(args, api, extraOptions)
      window.location.reload()
    } else {
      // refresh failed - do something like redirect to login or show a "retry" button
      logout_tradly()
    }
  }
  return result
}

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: tagTypesList,
})
