export const pages_keys = 'home,listing_details,checkout'
export const style_keys =
  'header,body,button_styles,typography,listing_card,dynamic_footer,dynamic_header,form_styles'
export const seo_keys =
  'meta_account_category_description,meta_account_category_title,meta_account_description,meta_account_title,meta_description,meta_title'

export const export_config_keys = (group_name) => {
  switch (group_name) {
    case 'pages':
      return pages_keys
    case 'styles':
      return style_keys
    case 'seo':
      return seo_keys

    default:
      return ''
  }
}
