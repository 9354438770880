/* eslint-disable no-use-before-define */
import axios from 'axios'
import { getAuthKey } from 'constant/functions'
import { base_url } from 'constant/url'

export const proxy_api_call = async ({
  method,
  path,
  data,
  params,
  onSuccess,
  onError,
  isLoading,
  setISLoading,
}) => {
  setISLoading && setISLoading(true)
  try {
    let parameters = {
      url: path,
      method: method,
      params: params ?? {},
    }

    if (method !== 'GET' && Object.keys(data).length > 0) {
      parameters.data = data ? { data } : {}
    }

    const response_json = await axios(parameters)
    // console.log(response_json)
    setISLoading && setISLoading(false)
    return onSuccess(response_json.data)
  } catch (error) {
    setISLoading && setISLoading(false)
    return onError(error?.response?.data?.error)
  }
}
