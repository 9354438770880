import React, { useState, useRef, useEffect } from 'react'
import Link from 'next/link'
import Transition from '../utils/Transition.js'
import Dropdown from '../utils/Dropdown'

function Header2() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const [top, setTop] = useState(true)

  const trigger = useRef(null)
  const mobileNav = useRef(null)

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return
      if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target))
        return
      setMobileNavOpen(false)
    }
    document.addEventListener('click', clickHandler)
    return () => document.removeEventListener('click', clickHandler)
  })

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!mobileNavOpen || keyCode !== 27) return
      setMobileNavOpen(false)
    }
    document.addEventListener('keydown', keyHandler)
    return () => document.removeEventListener('keydown', keyHandler)
  })

  // detect whether user has scrolled the page down by 10px
  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    }
    window.addEventListener('scroll', scrollHandler)
    return () => window.removeEventListener('scroll', scrollHandler)
  }, [top])

  return (
    <header
      className={`fixed w-full z-30 md:bg-opacity-90 transition duration-300 ease-in-out ${
        !top && 'bg-white  blur  shadow-lg'
      }`}
    >
      <div className="max-w-6xl mx-auto px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">
          {/* Site branding */}
          <div className="flex-shrink-0 mr-4">
            {/* Logo */}
            <Link href="/">
              <div className="block" aria-label="Tradly">
                {' '}
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 126 126"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M63 0C97.7939 0 126 28.2061 126 63C126 97.7939 97.7939 126 63 126C28.2061 126 0 97.7939 0 63C0 28.2061 28.2061 0 63 0Z"
                    fill="url(#paint0_linear)"
                  />
                  <path
                    opacity="0.5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M63 119C93.9279 119 119 93.9279 119 63C119 32.0721 93.9279 7 63 7C32.0721 7 7 32.0721 7 63C7 93.9279 32.0721 119 63 119Z"
                    stroke="white"
                    stroke-width="1.4"
                  />
                  <path
                    opacity="0.5"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M63 105C86.196 105 105 86.196 105 63C105 39.804 86.196 21 63 21C39.804 21 21 39.804 21 63C21 86.196 39.804 105 63 105Z"
                    stroke="white"
                    stroke-width="1.4"
                  />
                  <path
                    d="M108.282 44.2442C105.799 38.2551 102.162 32.8652 97.6482 28.3518C88.7809 19.4845 76.5309 14 63 14C49.469 14 37.219 19.4845 28.3517 28.3518C23.8383 32.8652 20.2012 38.2551 17.7178 44.2442"
                    stroke="white"
                    stroke-width="15.4"
                    stroke-linecap="round"
                  />
                  <path
                    d="M63.0001 14.0001V111.222"
                    stroke="white"
                    stroke-width="15.4"
                    stroke-linecap="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear"
                      x1="126"
                      y1="0"
                      x2="126"
                      y2="126"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#2BDBC0" />
                      <stop offset="1" stop-color="#13B58C" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </Link>
          </div>

          {/* Desktop navigation */}
          <nav className="hidden md:flex md:flex-grow">
            {/* Desktop menu links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              {/* <li>
                <Link href="/pricing">
                  <a className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                    {' '}
                    Pricing{' '}
                  </a>
                </Link>
              </li> */}
              <li>
                <Link href="/templates">
                  <a className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                    Templates
                  </a>
                </Link>
              </li>
              {/* <li>
                <Link href="/about">
                  <a className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                    About us
                  </a>
                </Link>
              </li> */}
              <li>
                <Link href="/tutorials">
                  <a className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                    Tutorials
                  </a>
                </Link>
              </li>
              <li>
                <Link href="/blog">
                  <a className="text-gray-600 hover:text-gray-900 px-3 lg:px-5 py-2 flex items-center transition duration-150 ease-in-out">
                    Blog
                  </a>
                </Link>
              </li>
              {/* 1st level: hover */}
              <Dropdown title="Resources">
                {/* 2nd level: hover */}
                <li>
                  <Link href="/docs/setup">
                    <a className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">
                      Docs
                    </a>
                  </Link>
                </li>
                <li>
                  <Link href="/support">
                    <a className="font-medium text-sm text-gray-600 hover:text-gray-900 flex py-2 px-5 leading-tight">
                      Support center
                    </a>
                  </Link>
                </li>
              </Dropdown>
            </ul>

            {/* Desktop sign in links */}
            <ul className="flex flex-grow justify-end flex-wrap items-center">
              <li>
                <Link href={'/support'}>
                  <a className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                    Contact Sales
                  </a>
                </Link>
              </li>
              <li>
                <Link href="https://auth.tradly.app/login">
                  <a className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                    Sign in
                  </a>
                </Link>
              </li>
              <li>
                <Link href="https://auth.tradly.app/register">
                  <a className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3">
                    <span>Get started - FREE!</span>
                    <svg
                      className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                      viewBox="0 0 12 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                        fillRule="nonzero"
                      />
                    </svg>
                  </a>
                </Link>
              </li>
            </ul>
          </nav>

          {/* Mobile menu */}
          <div className="flex md:hidden">
            {/* Hamburger button */}
            <button
              ref={trigger}
              className={`hamburger ${mobileNavOpen && 'active'}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <span className="sr-only">Menu</span>
              <svg
                className="w-6 h-6 fill-current text-gray-900"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </button>

            {/*Mobile navigation */}
            <div ref={mobileNav}>
              <Transition
                show={mobileNavOpen}
                tag="nav"
                id="mobile-nav"
                className="absolute top-full h-screen pb-16 z-20 left-0 w-full overflow-scroll bg-white"
                enter="transition ease-out duration-200 transform"
                enterStart="opacity-0 -translate-y-2"
                enterEnd="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveStart="opacity-100"
                leaveEnd="opacity-0"
              >
                <ul className="px-5 py-2">
                  {/* <li>
                    <Link href="/pricing">
                      <a className="flex text-gray-600 hover:text-gray-900 py-2">Pricing</a>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link href="/about">
                      <a className="flex text-gray-600 hover:text-gray-900 py-2">About us</a>
                    </Link>
                  </li> */}
                  <li>
                    <Link href="/templates">
                      <a className="flex text-gray-600 hover:text-gray-900 py-2">Templates</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/tutorials">
                      <a className="flex text-gray-600 hover:text-gray-900 py-2">Tutorials</a>
                    </Link>
                  </li>
                  <li>
                    <Link href="/blog">
                      <a className="flex text-gray-600 hover:text-gray-900 py-2">Blog</a>
                    </Link>
                  </li>
                  <li className="py-2 my-2 border-t border-b border-gray-200">
                    <span className="flex text-gray-600 hover:text-gray-900 py-2">Resources</span>
                    <ul className="pl-4">
                      <li>
                        <Link href="/docs/setup">
                          <a className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">
                            Docs
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/support">
                          <a className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">
                            Support center
                          </a>
                        </Link>
                      </li>
                      {/* <li>
                        <Link href="/404">
                          <a className="text-sm flex font-medium text-gray-600 hover:text-gray-900 py-2">
                            404
                          </a>
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                  <li>
                    <Link href={'/support'}>
                      <a className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">
                        Contact Sales
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://auth.tradly.app/login">
                      <a className="flex font-medium w-full text-gray-600 hover:text-gray-900 py-2 justify-center">
                        Sign in
                      </a>
                    </Link>
                  </li>
                  <li>
                    <Link href="https://auth.tradly.app/register">
                      <a className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 w-full my-2">
                        <span>Get started - FREE!</span>
                        <svg
                          className="w-3 h-3 fill-current text-gray-400 flex-shrink-0 ml-2 -mr-1"
                          viewBox="0 0 12 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z"
                            fill="#999"
                            fillRule="nonzero"
                          />
                        </svg>
                      </a>
                    </Link>
                  </li>
                </ul>
              </Transition>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header2
