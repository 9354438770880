import React, { createContext, useContext, useState } from 'react'

const PopupContext = createContext()

export function useGlobalPopup() {
  return useContext(PopupContext)
}

export function GlobalPopupProvider({ children }) {
  const [isGlobalPopupOpen, setIsGlobalPopupOpen] = useState(false)
  const [all_data, set_all_data] = useState(null)

  const openPopup = (all_props) => {
    set_all_data(all_props)
    setIsGlobalPopupOpen(true)
  }
  const closePopup = () => {
    set_all_data(null)
    setIsGlobalPopupOpen(false)
  }
  return (
    <PopupContext.Provider value={{ isGlobalPopupOpen, openPopup, closePopup, all_data }}>
      {children}
    </PopupContext.Provider>
  )
}
