export const team_select = {
  title: 'Which team are you on?',
  subtitle: 'This will help us to personalise your experience',
  items: [
    { label: 'Engineering', value: 'engineering' },
    { label: 'Design', value: 'design' },
    { label: 'Product Management', value: 'product_management' },
    { label: 'Sales & Marketing', value: 'sales_marketing' },
    { label: 'Others', value: 'others' },
  ],
}

export const about_user = {
  title: 'A little about you',
  subtitle: `We'll suggest different approach to launch`,

  items: [
    { label: 'Experienced Developer (knowledge on headless API)', value: 'developer' },
    { label: 'Soloprenuer with tech startup background ', value: 'techie' },
    { label: 'Soloprenuer with no tech startup background before', value: 'passionate' },
    { label: '5-50 Staff company with developers', value: 'tech-startup' },
    { label: '5-50 staff company without developers', value: 'nontech-startup' },
    { label: 'Mid market / Enterprise Companies with min ARR $1Million', value: 'Mid-Enterprise' },
    { label: 'Development Agency', value: 'devagency' },
    { label: 'Others', value: 'others' },
  ],
}

export const heard_from = {
  title: 'Where did you hear about Tradly?',
  subtitle: 'This will help us to be there with you. ',

  items: [
    { label: 'Search Engine', value: 'search_engine' },
    { label: 'Referral', value: 'referral' },
    { label: 'Friend', value: 'friend' },
    { label: 'Facebook / Instagram', value: 'facebook_instagram' },
    { label: 'Youtube', value: 'youtube' },
  ],
}

export const product_interested = {
  title: 'Product Interested',
  subtitle: 'Headless API + SuperAdmin comes by default',

  items: [
    { label: 'Headless API', value: 'API' },
    { label: 'Headless API with web templates ', value: 'pre-built-web' },
    { label: 'Headless API with pre-built mobile apps ', value: 'pre-built-mobile' },
    // { label: 'Headless API with apps built in custom package', value: 'custom' },
  ],
}
