import React, { createContext, useContext, useReducer } from 'react'
import {
  ALL_SITES,
  DRAFT_CONFIGS,
  SELECTED_DRAFT_CONFIGS,
  SELECTED_SITE,
  SELECTED_TENANT_CONFIGS,
  TENANT_CONFIGS,
  SELECTED_EDITIOR_PREVIEW_SIZE,
  ALL_DYNAMIC_PAGES,
} from './actionTypes'

//  initial state
const initialState = {
  // Define your initial state properties here

  user_details: null,
  tenant_details: null,
  sites_list: null,
  selected_domain: null,
  tenant_configs_list: null,
  draft_configs_list: null,
  selected_tenant_configs: null,
  selected_draft_configs: null,
  selected_editor_preview_size: '',
  dynamic_pages: null,
}

// reducer function
const appReducer = (state, action) => {
  switch (action.type) {
    // Define different cases to handle state updates
    // For example: case 'UPDATE_NAME': return { ...state, name: action.payload };
    case SELECTED_SITE:
      return { ...state, selected_domain: action.payload }
    case ALL_SITES:
      return { ...state, sites_list: action.payload }
    case TENANT_CONFIGS:
      return { ...state, tenant_configs_list: action.payload }
    case DRAFT_CONFIGS:
      return { ...state, draft_configs_list: action.payload }
    case SELECTED_TENANT_CONFIGS:
      return { ...state, selected_tenant_configs: action.payload }
    case SELECTED_DRAFT_CONFIGS:
      return { ...state, selected_draft_configs: action.payload }
    case SELECTED_EDITIOR_PREVIEW_SIZE:
      return { ...state, selected_editor_preview_size: action.payload }
    case ALL_DYNAMIC_PAGES:
      return { ...state, dynamic_pages: action.payload }
    default:
      return state
  }
}

//  context
const AppContext = createContext()

// custom hook to use the context
export const useAppContext = () => useContext(AppContext)

//  Context provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState)

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>
}
