import {
  auth_cookie_name,
  cookies_domain_name,
  global_auth_cookie_name,
  refresh_cookie_name,
  tenant_cookie_name,
  uuid_cookie_name,
} from 'constant/url'
import { deleteCookie } from 'cookies-next'

export const logout_tradly = () => {
  deleteCookie(auth_cookie_name, { domain: cookies_domain_name, path: '/' })
  deleteCookie(refresh_cookie_name, { domain: cookies_domain_name, path: '/' })
  deleteCookie(uuid_cookie_name, { domain: cookies_domain_name, path: '/' })
  deleteCookie(tenant_cookie_name, { domain: cookies_domain_name, path: '/' })
  deleteCookie(global_auth_cookie_name, { domain: cookies_domain_name, path: '/' })
  window.location.reload()
}
