import { proxy_api_call } from '@/WebHandler/proxy_api_call'
import { templateEncryptDataWithAES } from 'constant/functions'
import axios from 'axios'
import { apiSlice } from '@/redux/api/apiSlice'
import { tagTypes } from '@/redux/api/TagTypes'

export const currency_and_language_setup = async ({
  setIsLoading,
  router,
  selected_language,
  selected_currency,
  onSuccessSetup,
  editable_ids,
  dispatch,
}) => {
  try {
    setIsLoading(true)

    if (editable_ids?.language_id) {
      const language_resp = await axios.put(
        `/api/language/tenant?id=${editable_ids?.language_id}`,
        {
          language: {
            language_id: selected_language?.value,
            active: true,
            default: true,
          },
        }
      )
    } else {
      const language_resp = await axios.post('/api/language/tenant', {
        language: {
          language_id: selected_language?.value,
          active: true,
          default: true,
        },
      })
    }

    if (editable_ids?.currency_id) {
      const currency_resp = await axios.put(`/api/currency?id=${editable_ids?.currency_id}`, {
        currency: selected_currency,
      })
    } else {
      const currency_resp = await axios.post('/api/currency', {
        currency: selected_currency,
      })
    }

    //
    dispatch(apiSlice.util.invalidateTags([tagTypes.tenant_languages, tagTypes.currencies]))
    setIsLoading(false)
    if (onSuccessSetup) {
      onSuccessSetup()
    } else {
      router.push('/onboarding#2')
    }
  } catch (error) {
    setIsLoading(false)
    console.log(error)
  }
}
