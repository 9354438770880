import { tagTypes } from '@/redux/api/TagTypes'
import { apiSlice } from '@/redux/api/apiSlice'

export const currencyApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //Get  user details
    getCurrencies: builder.query({
      query: () => {
        return `/currency`
      },
      providesTags: [tagTypes.currencies],
    }),
  }),
})

export const { useGetCurrenciesQuery } = currencyApi
