import React, { useEffect, useState } from 'react'

const Countdown = () => {
  // let countDownDate = new Date('July 31, 2022 23:59:59').getTime()
  // const [days, setDays] = useState()
  // const [hours, setHours] = useState()
  const [minutes, setMinutes] = useState()
  // const [seconds, setSeconds] = useState()

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     // Get Date Now
  //     let dateNow = new Date().getTime()

  //     // Find The Date Difference Between Now And Countdown Date
  //     let dateDiff = countDownDate - dateNow

  //     // Get Time Units
  //     // let days = Math.floor(dateDiff / 1000 / 60 / 60 / 24);

  //     setDays(Math.floor(dateDiff / (1000 * 60 * 60 * 24)))
  //     setHours(Math.floor((dateDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)))
  //     setMinutes(Math.floor((dateDiff % (1000 * 60 * 60)) / (1000 * 60)))
  //     setSeconds(Math.floor((dateDiff % (1000 * 60)) / 1000))

  //     // if (dateDiff < 0) {
  //     //   clearInterval(counter)
  //     // }
  //   }, 1000)

  //   return () => clearInterval(interval)
  // }, [])

  return minutes ? (
    <div className="flex items-center justify-center gap-2">
      {/* <p className=" w-10 h-10  flex items-center justify-center text-primary rounded-sm bg-transparent border border-primary ">
        {days * 24 + hours}
      </p>
      <p> :</p>
      <p className="w-10 h-10  flex items-center justify-center text-primary rounded-sm bg-transparent border border-primary ">
        {minutes}
      </p>
      <p> :</p>
      <p className="w-10 h-10  flex items-center justify-center text-primary rounded-sm bg-transparent  border border-primary ">
        {seconds}
      </p> */}
    </div>
  ) : (
    <></>
  )
}

export default Countdown
